var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "reset-password-container" },
    [
      _c(
        "v-card",
        { staticClass: "elevation-0" },
        [
          _c(
            "div",
            {
              staticClass:
                "text-body-0 text-uppercase font-weight-semibold mt-3"
            },
            [_vm._v(" " + _vm._s(_vm.$t("changePassword.title")) + " ")]
          ),
          _c("p", [_vm._v(_vm._s(_vm.$t("changePassword.subtitle")))]),
          _c(
            "v-card-text",
            { staticClass: "pa-0" },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  staticClass: "reset-password",
                  attrs: { "lazy-validation": _vm.lazy },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Vecchia password*",
                      "append-icon": _vm.showPassword ? "$eye" : "$eyeOff",
                      type: _vm.showPassword ? "text" : "password",
                      rules: _vm.passwordRules,
                      required: "",
                      outlined: "",
                      rounded: "",
                      dense: ""
                    },
                    on: { "click:append": _vm.toggleShowPassword },
                    model: {
                      value: _vm.passwordOld,
                      callback: function($$v) {
                        _vm.passwordOld = $$v
                      },
                      expression: "passwordOld"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Nuova password*",
                      "append-icon": _vm.showPassword ? "$eye" : "$eyeOff",
                      type: _vm.showPassword ? "text" : "password",
                      rules: _vm.passwordRules,
                      required: "",
                      outlined: "",
                      rounded: "",
                      dense: ""
                    },
                    on: { "click:append": _vm.toggleShowPassword },
                    model: {
                      value: _vm.password,
                      callback: function($$v) {
                        _vm.password = $$v
                      },
                      expression: "password"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Conferma nuova password",
                      "append-icon": _vm.showPassword ? "$eye" : "$eyeOff",
                      type: _vm.showPassword ? "text" : "password",
                      rules: _vm.passwordConfirmRules,
                      required: "",
                      outlined: "",
                      rounded: "",
                      dense: "",
                      clearable: ""
                    },
                    on: { "click:append": _vm.toggleShowPassword },
                    model: {
                      value: _vm.passwordConfirm,
                      callback: function($$v) {
                        _vm.passwordConfirm = $$v
                      },
                      expression: "passwordConfirm"
                    }
                  })
                ],
                1
              ),
              _c("ResponseMessage", { attrs: { response: _vm.response } })
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "pa-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "reset-button",
                  attrs: {
                    color: "primary",
                    "min-width": "200",
                    large: "",
                    depressed: "",
                    rounded: "",
                    block: "",
                    disabled:
                      !_vm.valid ||
                      _vm.password == null ||
                      _vm.password != _vm.passwordConfirm
                  },
                  on: { click: _vm.handleResetPassword }
                },
                [_vm._v(_vm._s(_vm.$t("login.submitResetPassword")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }