<template>
  <v-container class="reset-password-container">
    <v-card class="elevation-0">
      <!-- <v-row no-gutters>
        <v-col cols="12" sm="4"></v-col>
      </v-row> -->
      <div class="text-body-0 text-uppercase font-weight-semibold mt-3">
        {{ $t("changePassword.title") }}
      </div>
      <p>{{ $t("changePassword.subtitle") }}</p>
      <v-card-text class="pa-0">
        <v-form
          ref="form"
          class="reset-password"
          v-model="valid"
          :lazy-validation="lazy"
        >
          <v-text-field
            v-model="passwordOld"
            label="Vecchia password*"
            :append-icon="showPassword ? '$eye' : '$eyeOff'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordRules"
            @click:append="toggleShowPassword"
            required
            outlined
            rounded
            dense
          ></v-text-field>
          <v-text-field
            v-model="password"
            label="Nuova password*"
            :append-icon="showPassword ? '$eye' : '$eyeOff'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordRules"
            @click:append="toggleShowPassword"
            required
            outlined
            rounded
            dense
          ></v-text-field>
          <v-text-field
            v-model="passwordConfirm"
            label="Conferma nuova password"
            :append-icon="showPassword ? '$eye' : '$eyeOff'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordConfirmRules"
            @click:append="toggleShowPassword"
            required
            outlined
            rounded
            dense
            clearable
          ></v-text-field>
        </v-form>
        <ResponseMessage :response="response" />
      </v-card-text>
      <v-card-actions class="pa-0">
        <v-btn
          @click="handleResetPassword"
          class="reset-button"
          color="primary"
          min-width="200"
          large
          depressed
          rounded
          block
          :disabled="!valid || password == null || password != passwordConfirm"
          >{{ $t("login.submitResetPassword") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<style lang="scss">
// .reset-password-container {
//   .reset-password {
//     width: 350px;
//   }
// }
</style>
<script>
import ResponseMessage from "@/components/ResponseMessage";
import categoryMixin from "~/mixins/category";
import { mapActions } from "vuex";

import {
  requiredValue,
  isPasswordMinimumLength
} from "~/validator/validationRules";
import AbbondanzaRegistrationService from "@/service/abbondanzaRegistrationService";

export default {
  name: "ResetPassword",
  components: { ResponseMessage },
  mixins: [categoryMixin],
  data() {
    return {
      showPassword: false,
      password: null,
      passwordOld: null,
      passwordConfirm: null,
      valid: true,
      lazy: true,
      passwordRules: [
        requiredValue("Digitare la password"),
        isPasswordMinimumLength()
      ],
      passwordConfirmRules: [
        v => !!v || "Confermare la password",
        v => v === this.password || "Le password non coincidono"
      ],
      errors: [],
      invalid: null,
      code: null,
      response: {},
      showForm: true
    };
  },
  methods: {
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    ...mapActions({ loadCart: "cart/loadCart" }),
    async handleResetPassword() {
      try {
        this.loading = true;
        const response = await AbbondanzaRegistrationService.changePassword({
          oldPassword: this.passwordOld,
          newPassword1: this.password,
          newPassword2: this.passwordConfirm
        });
        this.response = response.response;
        if (response.response.status == 0) {
          this.loadCart();
          //this.$router.push({ name: "Home" });
        }
      } catch (err) {
        if (err.response) {
          this.response = err.response.data.response;
        }
      } finally {
        this.loading = false;
        this.showForm = false;
      }
    },
    getQueryParams() {
      this.code = this.$route.query.code;
    }
  },
  created() {}
};
</script>

<style></style>
